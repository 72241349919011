.field-properties-popup .Token-droppable-field {
    width: 50% !important;
    margin: 0 auto !important;
    height: 80px !important;
}
.field-properties-popup .Token-droppable-field .Token-text {
    font-size: 12px;
    line-height: 2em;
}
.field-properties-popup .Token-droppable-field .Token-value {
    font-size: 20px;
    line-height: 2em;
}

.field-properties-popup .Token-container.black .Token-text {
    font-size: 10px !important;
}
.field-properties-popup .Token-container.black .Token-value {
    font-size: 20px !important;
    line-height: 2em;
}


.field-properties-popup .containerObjClass {
    width: 100px;
    height: 50px;
    float: left;
}
.field-properties-popup .objModelClass {
    width: 100%;
    height: 100%;
}
.field-properties-popup .coins-container {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.field-properties-popup .coins {
    width: 10px!important;
    height: 42px!important;
    /*position: absolute;
    width: 30px!important;
    height: 60px!important;
    top: 9px;*/
}

.coins-container .helper {
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.coins-container .coins:nth-child(2) {
    margin-left: -1px;
}
.coins-container .coins:nth-child(3) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(4) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(5) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(6) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(7) {
    margin-left: -1px;
}
.coins-container .coins:nth-child(8) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(9) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(10) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(11) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(12) {
    margin-left: -1px;
}
.coins-container .coins:nth-child(13) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(14) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(15) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(16) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(17) {
    margin-left: -1px;
}
.coins-container .coins:nth-child(18) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(19) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(20) {
    margin-left: -7px;
}
.coins-container .coins:nth-child(21) {
    margin-left: -7px;
}

.coins-container .Container-Card {
    /*position: absolute;
    width: 12px!important;
    height: 40px!important;
    transform: rotateY(57deg) rotateZ(-90deg);
    right: 1px;
    top: 5px;*/
    position: absolute;
    width: 40px!important;
    height: 40px!important;
    right: -11px;
    transform: rotateY(72deg) rotateZ(0deg);
    top: 50%;
    margin-top: -20px;
}

.card-droppable .Container-Card {
    position: absolute;
    width: 40px!important;
    height: 40px!important;
    transform: rotateZ(-90deg);
    right: 5px;
    top: 5px;
}


.coins-container .productCardContainer {
    /*position: absolute;
    width: 60px!important;
    height: 60px!important;
    transform: rotateY(60deg) rotateZ(-90deg);
    right: -4px;
    top: 10px;*/
    position: absolute;
    width: 40px!important;
    height: 40px!important;
    transform: rotateY(70deg) rotateZ(-90deg);
    right: -10px;
    top: 5px;
}
.coins-container .productCardImg {
    width: 100%;
    height: 100%;
}
.coins-container .productCardContainer .productCardValue {
    font-size: 22px;
    text-align: center;
    top: 45%;
    position: absolute;
    width: 100%;
    height: 24px;
    margin-top: -12px;
    font-weight: bolder;
    color: black;
}

/*tr:hover {
    color: white!important;
}*/