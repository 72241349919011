.Research-block-container {
    width: 100%;
}

.Research-bold-text {
    font-weight: bolder;
}

.Research-title-text-size {
    font-size: 9pt;
    margin-bottom: 0px;
}
.Research-subtitle-text-size {
    font-size: 7pt;
    float: right;
    font-weight: bold;
}

.Research-text-size {
    font-size: 6pt;
}

.Text-italic {
    font-style: italic;
}

.Research-row {
    width: 100%;
    height: 49px;
    padding: 5px;
    text-align: left;
    color: #FFFFFF;
    font-size: 7pt;
}

.Block-center {
    display: block;
    margin: auto 0;
}

.SimflexMAN .Standard-row {
    background-color: #B3C935;
}
.SimflexMAN .Compact-row {
    background-color: #FFCB1F;
}
.SimflexMAN .Plus-row {
    background-color: #4495D1;
}
.SimflexMAN .Luxus-row {
    background-color: #ED1651;
}

.Search-droppable-field {
    width: 100%;
    height: 100%;
    position: relative;
}
.Search-droppable-field::before {
    content: "";
    background-color: #FFFFFF;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
}

.Research-row .title {
	width: 21.19%;
	text-transform: uppercase;
}
.Research-row .number {
    width: 50%;
}
.Research-row .field {
    width: 28.81%;
}