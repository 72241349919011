.AuthorizationContainer {
  width: 100%;
  height: 100%;
}
/*.AuthorizationContainer .relativeContainer {
  position: relative;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}*/
.NotAuthorizedContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -250px;
  width: 500px;
  height: 50px;
  text-align: center;
}
.NotAuthorizedContainer .NotAuthorizedText {
  color: #002955;
  font-weight: 700;
}

.NotAuthorizedContainerLoaderImg {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -250px;
  width: 500px;
  height: 200px;
  text-align: center;
}
.loaderContainer {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}
.loaderContainer img {
  width: 100%;
  height: 100%;
}

.Team-name {
  font-size: 16px;
  color: #FFFFFF;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
.change-team-name-container {
  min-width: 10%; /*180px;*/
  padding: 5px;
}
.change-team-name-container input {
  width: 90%;
  margin: auto;
}
.SimflexMAN .change-team-name-container .team-name-text {
  font-size: 18px;
  color: #1D3648;
  text-align: left;
  font-weight: 900;
}
.SimflexMAN .change-team-name-container .team-name-btn {
  background-color: #1D3648;
  color: #FFFFFF;
  font-weight: bold;
  width: 100px;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  line-height: 2.5em;
  cursor: pointer;
  font-size: 12px;
  margin: 0 auto;
}

.year-number {
  font-size: 16px;
  color: #002955;
  font-weight: bold;
  text-align: center;
  margin: auto 0;
}


.container-fluid, .container {
  padding-left: 0px!important;
  padding-right: 0px!important;
}

.row {
  margin-left: 0px!important;
  margin-right: 0px!important;
}

.col, .col-1, .col-2, .col-3, .col-4, .col-8, .col-10 {
  padding-left: 0px!important;
  padding-right: 0px!important;
}
.Purchase-row .col-4 {
  padding-left: 5px!important;
  padding-right: 0px!important;
}
.col-7 {
  padding-left: 5px!important;
  padding-right: 0px!important;
}

body {
  background-color: #E8E8E8;
}

.files-library-btn {
  background-color: #E8E8E8;
  height: 100%;
  width: 30px;
  position: fixed;
  right: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 100;
  box-shadow: -20px 10px 40px rgba(0,0,0,.5);
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.files-library-btn .section {
  height: 50%;
  display: flex;
}
.files-library-btn .button {
  /*position: relative;
  top: 50%;*/
  transform: rotate(180.0deg);
  writing-mode: vertical-lr;
  cursor: pointer;
  /*margin-top: -70px;*/
  display: block;
  text-align: center;
  margin: auto;
}
.files-library-btn .button img {
  width: 24px;
  height: 22px;
  transform: rotate(180.0deg);
  margin: 5px auto;
  display: inline-block;
}
.files-library-btn .button.checklist img {
  width: 26px;
  transform: rotate(180.0deg);
  margin: 5px auto;
  display: inline-block;
}
.SimflexMAN .files-library-btn .button .text {
  text-align: center;
  /*line-height: 30px;*/
  margin: 0px auto;
  color: #1D3648;
  display: inline-block;
  vertical-align: middle;
}
.filesContainer, .checkListContainer {
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: -350px;
  width: 350px;
  z-index: 99;
  background-color: #E8E8E8;
  overflow: hidden;
  transition: right .3s ease;
  overflow-x: hidden;
  overflow-y: scroll;
}
.filesContainer.fullWidth, .checkListContainer.fullWidth {
  right: 30px;
  box-shadow: 20px 10px 40px rgba(0,0,0,.6);
}
.files-header {
  height: 80px;
  width: 100%;
  border-bottom: 1px solid #555A6E;
  display: flex;
}
.files-header-logo {
  min-width: 135px;
  display: flex;
  margin: auto;
}
.files-header-logo img {
  width: 24px;
  height: 22px;
  transform: rotate(90.0deg);
  margin: auto 0;
}
.SimflexMAN .files-header-logo .text {
  text-align: center;
  line-height: 80px;
  margin: auto 10px;
  color: #1D3648;
}


.kits-objects-btn {
  background-color: #E8E8E8;
  height: 100%;
  width: 30px;
  position: fixed;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 100;
  box-shadow: 20px 10px 40px rgba(0,0,0,.5);
  cursor: pointer;
}
.kits-objects-btn .button {
  /*position: relative;
  top: 50%;*/
  transform: rotate(180.0deg);
  writing-mode: vertical-lr;
  cursor: pointer;
  /*margin-top: -70px;
  display: flex;*/
  display: block;
  text-align: center;
  height: 100%;
}
.kits-objects-btn .button img {
  width: 30px;
  height: 28px;
  transform: rotate(180.0deg);
  margin: 5px auto;
  display: inline-block;
}
.SimflexMAN .kits-objects-btn .button .text {
  text-align: center;
  /*line-height: 30px;*/
  color: #1D3648;
  margin: 0px auto;
  display: inline-block;
  vertical-align: middle;
}
.kitContainer, .fieldProperties {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: -200px;
  width: 200px;
  z-index: 99;
  background-color: #E8E8E8;
  overflow: hidden;
  transition: left .3s ease;
  overflow-x: hidden;
  overflow-y: scroll;
}
.blur {
  /*min-height: 100%;*/
  width: 100%;
}
.kits-header {
  height: 80px;
  width: 100%;
  border-bottom: 1px solid #555A6E;
  display: flex;
}
.kits-header-logo {
  min-width: 125px;
  display: flex;
  margin: auto;
}
.kits-header-logo img {
  transform: rotate(90.0deg);
  width: 30px;
  height: 28px;
  margin: auto;
}
.SimflexMAN .kits-header-logo .text {
  text-align: center;
  line-height: 80px;
  margin: auto 5px;
  color: #1D3648;
  font-size: 13px;
}

.main-app-bg.width {
  width: 1650px;
}
.App.moveRight {
  width: 1650px;
  right: 180px;
}

.App.moveLeft {
  left: 220px;
}

.kitContainer.fullWidth,
.fieldProperties.fullWidth {
  left: 30px;
  box-shadow: 20px 10px 40px rgba(0,0,0,.6);
}

.container-text {
  font-size: 15px;
  color: #002955;
  line-height: 3em;
}
.kits-separator {
  background-color: #555A6E;
  height: 1px;
  width: 100%;
}

.object-container-div {
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.object-coins-div,
.object-pins-div,
.object-labels-div,
.object-starting-kits-div,
.object-tokens-div,
.object-delete-div {
  /*padding: 10px;*/
  /*margin-top: 10px;*/
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.object-labels-div .col {
  padding-left: 15px !important;
}

.object-delete-div .container-text {
  float: left;
}

.SimflexMAN .object-value {
  float: right;
  min-width: 25px;
  height: 24px;
  border-radius: 12px;
  background-color: #1D3648;
  color: white;
  text-align: center;
  line-height: 1em;
  margin-top: 10px;
  padding: 5px;
  font-size: 14px;
}
.SimflexMAN .object-value-center {
  width: 25px;
  height: 24px;
  border-radius: 12px;
  background-color: #1D3648;
  color: white;
  text-align: center;
  line-height: 1em;
  padding: 5px;
  font-size: 14px;
  margin: 10px auto 0 auto;
}



.model3dDraggable img {
  width: 95%;
  height: 95%;
}

.Board {
  padding: 20px;
}

.Left-column {
  width: 8.5%;
}

.Middle-column {
  width: 81%;
  padding-left: 15px;
  padding-right: 15px;
}

.Right-column {
  width: 10.5%;
}

.App-header {
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0px;
  background-color: #E8E8E8;
  z-index: 50;
  box-shadow: 20px 10px 40px rgba(0,0,0,.6);
}
.App-header-container {
  display: flex;
  width: 100%;
  height: 100%;
}
.header-logo-container {
  width: 16%; /*200px;*/
  margin: auto 0;
  display: flex;
  height: 100%;
}
.counter-container {
  min-width: 14%; /*140px;*/
  height: 100%;
  padding: 5px;
}
.year-container {
  width: 8%; /*100px;*/
  height: 100%;
  /*padding: 5px;*/
}
.chat-icon-container {
  height: 100%;
  min-width: 90px;
  display: flex;
}

.counter-container .bg-red {
  height: 100%;
  /*background: rgb(0,41,85);
  background: linear-gradient(135deg, rgba(0,41,85,1) -20%, rgba(145,0,39,1) 100%);*/
  border-radius: 10px;
  display: flex;
}
.counter-container .bg-red .row,
.year-container .bg-blue .row,
.player-name-container .bg-green .row,
.change-team-name-container .bg-blue .row {
  height: 50%;
}
.counter-container .bg-red .row .col,
.year-container .bg-blue .row .col,
.player-name-container .bg-green .col,
.change-team-name-container .bg-blue .col {
  margin: auto 0;
}

.SimflexMAN .year-container .header-section {
  color: #1D3648;
  font-weight: 700;
  text-align: center;
  font-size: 16px;
  line-height: 70px;
  margin-right: 10px;
}
.year-container .bg-blue {
  height: 100%;
  /*background: rgb(0,41,85);
  background: linear-gradient(135deg, rgba(0,41,85,1) -20%, rgba(33,146,64,1) 100%);*/
  border-radius: 10px;
  display: flex;
}
.SimflexMAN .year-value {
  font-size: 18px;
  font-weight: 900;
  color: #1D3648;
  text-align: center;
  line-height: 70px;
}

.SimflexMAN .counter-container .header-section {
  color: #1D3648;
  font-weight: 500;
  text-align: center;
  font-size: 13px;
}
.counter-title {
  font-size: 15px;
  color: #910027;
  text-align: center;
}
.SimflexMAN .counter-value {
  font-size: 18px;
  font-weight: 900;
  color: #1D3648;
  text-align: center;
}

.change-team-name-container .team-data-content {
  margin: auto 0;
  width: 100%;
}
.change-team-name-container .form .team-data-content {
  margin: 0px !important;
}

.year-container .bg-blue.data {
  display: flex;
  height: 60%;
}
.year-container .team-data-content,
.counter-container .team-data-content,
.player-name-container .team-data-content {
  margin: auto 0;
  width: 100%;
}

.SimflexMAN .change-team-name-container .header-section {
  color: #1D3648;
  font-weight: 500;
  text-align: left;
  font-size: 13px;
}

.change-team-name-container .bg-blue {
  height: 100%;
  /*background: rgb(0,41,85);
  background: linear-gradient(135deg, rgba(0,41,85,1) -20%, rgba(90,172,255,1) 100%);*/
  border-radius: 10px;
  display: flex;
}
.change-team-name-container .bg-blue.form {
  height: 100%!important;
}
.widget-content {
  width: 80%;
  margin: auto;
}

.player-name-container {
  min-width: 8% /*100px*/;
  padding: 5px;
}
.SimflexMAN .player-name-container .header-section {
  color: #1D3648;
  font-weight: 500;
  text-align: left;
  font-size: 13px;
}
.player-name-container .bg-green {
  height: 100%;
  /*background: rgb(0,41,85);
  background: linear-gradient(135deg, rgba(0,41,85,1) -20%, rgba(90,172,255,1) 100%);*/
  border-radius: 10px;
  display: flex;
}

.cursorPointer {
  cursor: pointer;
}

.team-form-container {
  display: flex;
  height: 100%;
}

.team-name-title {
  color: #FFFFFF;
  text-align: end;
}
.SimflexMAN .player-name {
  text-align: left;
  color: #1D3648;
  font-weight: 900;
  font-size: 18px;
}


.header-separator {
  background-color: #CACACA;
  width: 2px;
  height: 40px;
  margin: auto 10px auto 0px;
}
.header-separator-2 {
  background-color: #CACACA;
  width: 2px;
  height: 40px;
  margin: auto 0px auto 0px;
}

.header-logo-container-2{
  margin: 0 auto;
  display: flex;
}

.zoom-container {
  margin: auto 10px;
  min-width: 80px;
}
.zoom-btn-container {
  display: flex;
  cursor: pointer;
  height: 100%;
}
.zoom-btn-container .zoom-btn {
  width: 25px;
  height: 25px;
  margin: auto;
}
.zoom-btn-container .text {
  color: #002955;
  text-align: center;
  margin-left: 5px;
  font-size: 15px;
}


.SimflexMAN .view-only {
  color: #1D3648;
  font-weight: 900;
  text-align: center;
  width: 100%;
  font-size: 20px
}

.kit-menu-btn {
  width: 25px;
  cursor: pointer;
  margin-right: 20px;
}

.SimflexMAN.main-app-bg {
  background-color: #1D3648;
}

.SimflexMAN .App {
  text-align: center;
  padding: 10px;
  /*left: 20px;*/
  position: relative;
  transition: all .3s ease;
  min-width: 1280px;
  max-width: 1280px;
  margin: 80px auto 0px auto;

  background-color: #1D3648;
  /* background-image: url("../../assets/images/main-bg.png");
  background-repeat: repeat; */
}

.App-logo {
  height: 60px;
  margin: auto;
}

.Center-block {
  position: relative;
  padding: 10px 20px 20px 20px !important;
}

.Center-block::before {
  content: "";
  background-color: #FFFFFF;
  opacity: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 0;
}

.Finance-container-margin {
  width: 43.8%!important;
  z-index: 1;
  padding-right: 5px;
}

.SimflexMAN .Research-Purchasing-container {
  background-color: #BEC5CE;
  border: 1px solid #000;
  width: 56.2%!important;
  text-align: left;
  padding: 0px 5px 5px 5px !important;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
}

.Research-Container {
  width: 53%;
  padding-right: 5px;
}
.Purchase-Container {
  width: 47%;
}

.SimflexMAN .Production-container {
  background-color: #BEC5CE;
  border: 1px solid #000;
  margin-top: 5px;
  text-align: left;
  padding: 0px 5px 5px 5px !important;
}

.SimflexMAN .Sales-Stock-block {
  background-color: #BEC5CE;
  border: 1px solid #000;
  text-align: left;
  padding: 5px 5px 5px 5px !important;
  margin-top: 5px;
}

.Number-box-container {
  margin: auto 0;
}

.Number-box {
  background-color: #FFFFFF;
  border: 1px solid #000000;
  font-size: 8pt;
  color: #000000;
  font-weight: bold;
  display: inline-block;
  width: 20px;
  height: 20px;
  text-align: center;
  margin-right: 2px;
}

.Block-title-bold-text {
  font-weight: bolder;
}

.Block-title-text-size {
  font-size: 10pt;
}

.Text-italic {
  font-style: italic;
}
.Text-bold {
  font-weight: bold;
}

/************** Common *************/
.Left-block .Text-bold-size,
.Right-block .Text-bold-size {
  font-weight: bold;
  font-size: 6pt;
  word-break: break-word;
  margin-bottom: 4px;
}

.Left-block .Text-size,
.Right-block .Text-size {
  font-size: 3.5pt;
  word-break: break-word;
  margin-bottom: 4px;
}

/***********************************/

/*body {
  --light: ambient-light(#fff, .8),
  point-light(#f00, 1) 50% 50% 50% relative,
  point-light(#0f0, 1) 0px 300px 800px absolute,
  point-light(#00f,24) 700px 400px 800px static;
}*/

.model3dDraggable {
  width: 82px;
  height: 40px;
  margin: 0 auto;
}



.productColorLabelNumber {
  width: 26px;
  height: 26px;
  border-radius: 13px;
  border: 1px solid #707070;
  background-color: #FFFFFF;
  text-align: center;
  color: #707070;
  margin-bottom: 2px;
  font-size: 12px;
  line-height: 2em;
  cursor: pointer;
}

.productCardDraggable {
  position: relative;
  /*width: 48px;
  height: 40px;*/
  margin: 3px;
  cursor: grab;
  max-width: 48px;
}
.productCardDraggableImg {
  width: 100%;
  height: 100%;
}
.productCardValue {
  font-size: 16px;
  text-align: center;
  top: 45%;
  position: absolute;
  width: 100%;
  height: 24px;
  margin-top: -12px;
  font-weight: bolder;
}

.containerObjClass {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}



.token-img-view {
  width: 100%;
  height: 100%;
}
.draggable-coins-container {
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.draggable-coins {
  width: 100%;
  height: 100%;
  cursor: grab;
}
.draggable-pin-container{
  width: 40px;
  height: 40px;
  margin: 0 auto;
}
.draggable-pin {
  width: 100%;
  height: 100%;
}

canvas {
  z-index: 5;
}

.zIndex-3 {
  z-index: 3;
}

.margin-V-Center {
  margin: auto 0;
}
.full-width-height {
  width: 100%;
  height: 100%;
}


.coinValueTxt {
  font-size: 10px;
  color: white;
  text-align: center;
}

.removeDroppable {
  width: 30px;
  height: 30px;
  /*margin: 0 auto;*/
  float: right;
  margin-top: 8px;
}
.removeDroppable img {
  width: 100%;
  height: 100%;
}


.starting-kit-container {
  height: 80px;
  width: 40px;
  text-align: center;
  margin: 0 auto;
}
.starting-kit-container .objModelClass {
  width: 95%;
  height: 100%;
}

.sold-units-popup .popup-content {
  width: 360px !important;
  padding: 0px !important;
  border-radius: 20px;
  overflow: hidden;
}
.sold-units-popup .popup-header {
  height: 45px;
  background-color: #002955;
  color: #FFFFFF;
  /* margin: auto 0; */
  text-align: center;
  padding-top: 10px;
}
.sold-units-popup .popup-body {
  padding: 20px;
  color: #002955;
}
.sold-units-popup input {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #002955;
}
.sold-units-popup .btn {
  background-color: #002955!important;
  border-color: #002955 !important;
  width: 100px;
  height: 30px;
  border-radius: 15px;
  font-weight: 600;
}

.sold-units-popup .btn:focus,
.sold-units-popup .btn:active {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.field-properties-popup .popup-content {
  width: 360px !important;
  padding: 20px !important;
  border-radius: 20px;
  overflow: hidden;
}
.field-properties-popup table {
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
}
.field-properties-popup .prevCoinImg {
  width: 30px;
  height: 30px;
}
.field-properties-popup .contains-title {
  font-size: 20px;
  font-weight: 700;
  color: #3A3A3A;
  margin-top: 10px;
}
.field-properties-popup .bold-text {
  font-weight: bold;
}
.purchase-btn {
  color: #FFFFFF;
  background-color: #002955 !important;
  border: 1px solid #002955 !important;
  height: 30px;
  width: 100%;
  border-radius: 15px;
  font-weight: 600;
}
.purchase-btn:hover,
.purchase-btn:active,
.purchase-btn:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.rent-btn {
  color: #002955;
  background-color: #FFFFFF !important;
  border: 1px solid #002955 !important;
  height: 30px;
  width: 100%;
  border-radius: 15px;
  font-weight: 600;
}
.rent-btn:hover,
.rent-btn:active,
.rent-btn:focus {
  color: #002955;
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.questions-container {
  margin-top: 10px;
}
.question-text {
  color: #002955;
}
.questions-container button {
  background-color: #002955 !important;
  border-color: #002955 !important;
  width: 100px;
  height: 30px;
  border-radius: 15px;
  font-weight: 600;
}
.questions-container button:focus,
.questions-container button:active {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.question-input {
  margin-bottom: 5px;
}
.question-input input {
  width: 100%;
  border: 1px solid #002955;
  border-radius: 5px;
}
.horizontal-separator{
  width: 100%;
  height: 1px;
  margin-bottom: 20px;
  background-color: #EBEBEB;
}



.Token-container-draggable {
  background-color: #BC1700;
  width: 100%;
  height: 100%;
  width: 74px;
  height: 45px;
  color: white;
  margin: 0;
  cursor: grab;
}
.Token-container-draggable.black {
  background-color: #000000 !important;
}
.Token-container-draggable .Token-value {
  height: 60%;
  font-size: 16px;
  font-weight: bolder;
  text-align: center;
  line-height: 2em;
}
.Token-container-draggable .Token-text {
  height: 40%;
  font-size: 5px;
  text-align: center;
  font-weight: bold;
}
.Token-container-draggable.black .Token-value {
  color: #f0dba6;
}
.Token-container-draggable.black .Token-text {
  color: #f0dba6;
  font-size: 6px !important;
}


.fileRow {
  min-height: 40px;
  border-bottom: 1px solid #555A6E;
}
.fileRow .file-link {
  padding: 15px;
}
.fileRow .file-link a {
  color: #002955;
  font-size: 14px;
}

.chat-container {
  display: flex;
  cursor: pointer;
}
.chat-container img {
  height: 20px;
  width: 20px;
  margin: auto 0;
}
.chat-container .chat-title {
  font-size: 16px;
  color: #002955;
  font-weight: bold;
  text-align: center;
  margin: auto 5px;
  line-height: 70px;
}

.App-header .container,
.App-header .row,
.App-header .col {
  height: 100%
}

.logout-btn-container {
  margin: auto;
  width: 15%;
  display: flex;
  height: 100%;
}
.logout-btn {
  color: #FFFFFF;
  width: 36px;
  height: 36px;
  text-align: center;
  line-height: 80px;
  cursor: pointer;
  font-size: 12px;
  /*margin: 0 auto;*/
}
.SimflexMAN .language-btn {
  color: #1D3648;
  line-height: 80px;
  cursor: pointer;
  font-size: 14px;
  width: 80px;
  font-weight: bold;
}
.language-btn:hover {
  text-decoration: underline;
}
.logout-btn img {
  width: 100%;
  height: 100%;
}
.SimflexMAN .undo-btn {
  background-color: #1D3648;
  color: #FFFFFF;
  font-weight: bold;
  width: 100px;
  height: 36px;
  border-radius: 18px;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  font-size: 10px;
  margin: 0 auto;
}


.dragging {
  opacity: 0.5;
}

.rc-tooltip {
  z-index: 49!important;
  font-size: 10px!important;
}
.SimflexMAN .rc-tooltip-inner {
  background-color: #1D3648!important;
}
.SimflexMAN .rc-tooltip-arrow{
  border-top-color: #1D3648!important;
}
.rc-tooltip-placement-topRight .rc-tooltip-arrow {
  right: 10% !important;
}
.rc-tooltip-inner {
  padding: 5px!important;
  min-height: 16px!important;
}

.chat-count {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #901027;
  text-align: center;
  color: white;
  font-size: 12px;
  line-height: 20px;
  margin-left: -14px;
  margin-top: 16px;
  font-weight: 500;
}




/* Home Page */
.form-body {
  height: 100%;
}
.form-body > .row {
  position: relative;
  margin-left: 0;
  margin-right: 0;
  height: 100%;
}
.img-holder {
  width: 550px;
  background-color: #000;
}
.img-holder {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 550px;
  min-height: 700px;
  height: 100%;
  overflow: hidden;
  background-color: #000000;
  /* padding: 60px; */
  text-align: center;
  z-index: 999;
}
.img-holder .bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-image: url(../../assets/images/img1.jpg);
  background-size: cover;
  background-position: center;
  z-index: -1;
}
.img-holder .info-holder {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.form-holder {
  margin-left: 550px;
}
.form-holder {
  width: 100%;
  background: white;
}
.form-holder .form-content {
  position: relative;
  text-align: center;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 60px;
  min-height: 100%;
}
.form-content .form-items {
  max-width: 340px;
  text-align: left;
}
.form-content .form-items {
  display: inline-block;
  width: 100%;
  max-width: 340px;
  text-align: left;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.form-content .form-items {
  max-width: 340px;
  text-align: left;
}
.form-content .form-items {
  display: inline-block;
  width: 100%;
  max-width: 340px;
  text-align: left;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.website-logo-inside {
   margin-bottom: 20px;
}
.SimflexMAN .form-content h3 {
  color: #1D3648;
  text-align: left;
}
.form-content h3 {
  text-align: left;
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 10px;
}
.website-logo-inside a {
  display: inline-block;
}
.website-logo-inside .logo {
  background-image: url(../../assets/images/logo-dark3.svg);
}
.website-logo-inside .logo {
  display: inline-block;
  background-image: url(../../assets/images/logo-light2.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.website-logo-inside .logo img.logo-size {
  opacity: 0 !important;
}
.website-logo-inside .logo img {
  width: 250px;
}

.relativeContainer {
  height: 100%;
}

.form-content p {
  color: #000;
  text-align: left;
}
.form-content p {
  font-size: 18px;
  font-weight: 300;
  line-height: 20px;
  margin-bottom: 30px;
}


/* Check list css */
.SimflexMAN .checkListTitle {
  background-color: #1D3648;
  padding: 5px;
  color: white;
  font-size: 14px;
  font-weight: 900;
}
.checkListRow {
  padding-left: 5px;
  padding-right: 5px;
}
.checkListSubtitle {
  font-size: 12px;
  color: #313131;
  font-weight: 600;
}
.checkListValue {
  font-size: 10px;
  color: #555555;
  white-space: pre-line;
  line-height: 10px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.checkListRow ul {
  padding-inline-start: 20px;
}
.checkListRow ul li {
  font-size: 12px;
}

.phaseSeparator {
  margin-bottom: 1rem;
}
.phaseLineSeparator {
  height: 1px;
  background-color: #ff0000;
  width: 100%;
}
.phaseText {
  font-size: 10px;
  color: red;
  font-weight: 800;
}

.arrow {
  border: solid #ff0000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}
.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-bottom: 2px;
}

/* Files and Links */
.files-section, .links-section {
  height: 50%;
  width: 100%;
}
.SimflexMAN .linksListTitle, .SimflexMAN .filesListTitle {
  background-color: #1D3648;
  padding: 5px;
  color: white;
  font-size: 14px;
  font-weight: 900;
}



#dropdown-item-button {
	background-color: transparent !important;
	border: none !important;
	color: #002955 !important;
	padding: 0px !important;
}

.dropdown-menu .dropdown-item {
	height: 30px !important;
	padding: 5px !important;
	line-height: 20px !important;
}