.Sales-title {
	font-size: 8pt;
	text-transform: uppercase;
}
.Sales-title.bold-text {
    font-weight: bold;
}

.sales-row-title {
    font-size: 6pt;
    margin: auto 0;
}

.SimflexMAN .sales-row-container {
    height: 40px;
    background-color: #8D9AA9;
    padding: 4px;
    margin-bottom: 5px;
}

.Sales-number-box {
    background-color: #FFFFFF;
    border: 1px solid #000000;
    font-size: 10pt;
    color: #000000;
    font-weight: bold;
    display: inline-block;
    width: 20px;
    height: 28px;
    text-align: center;
    margin-right: 2px;
}

.Sales-dot-class {
    width: 2px;
    height: 2px;
    background-color: #000000;
    margin: auto;
    border: 1px solid #000000;
    border-radius: 2px;
}

.SimflexMAN .Stock-blue-block {
    background-color: #4495D1;
    border: 1px solid #4495D1;
    width: 100%;
    margin-bottom: 5px;
    padding: 2px !important;
}
.SimflexMAN .Stock-red-block {
    background-color: #ED1651;
    border: 1px solid #ED1651;
    width: 100%;
    padding: 2px !important;
}

.SimflexMAN .Stock-green-block {
    background-color: #B3C935;
    border: 1px solid #B3C935;
    width: 100%;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 2px !important;
}
.SimflexMAN .Stock-yellow-block {
    background-color: #FFCB1F;
    border: 1px solid #FFCB1F;
    width: 100%;
    margin-right: 5px;
    padding: 2px !important;
}

.SimflexMAN .Stock-red-block .Colored-droppable-field {
    background-color: #FFFFFF;
    border: 0px solid #FFFFFF;
    height: 40px;
}
.SimflexMAN .Stock-blue-block .Colored-droppable-field {
    background-color: #FFFFFF;
    border: 0px solid #FFFFFF;
    height: 40px;
}
.SimflexMAN .Stock-green-block .Colored-droppable-field {
    background-color: #FFFFFF;
    border: 0px solid #FFFFFF;
    height: 40px;
}
.SimflexMAN .Stock-yellow-block .Colored-droppable-field {
    background-color: #FFFFFF;
    border: 0px solid #FFFFFF;
    height: 40px;
}


.colored-block .col {
    margin: 1px;
}

.sales-row-container .col {
    margin: auto 0;
}

.Sales-number-height {
    height: 20px;
}
.Sales-dot-container {
    position: relative;
    height: 8px;
}

.Sales-dot-container .pinImgView {
    margin-left: -5px !important;
    margin-top: -21px !important;
}